import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import './Login.css';

// This is your class component unchanged
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      error: ''
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { username, password } = this.state;
    const apiUrl = 'https://pokonajlabirynt.pl/api/login';

    try {
      const response = await axios.post(apiUrl, { username, password });
      localStorage.setItem('token', response.data.token);
      this.props.onLoginSuccess(); // Call the function passed via props
    } catch (error) {
      this.setState({ error: 'Błąd logowania' });
      console.error('Błąd logowania', error);
    }
  };

  render() {
    const { username, password, error } = this.state;

    return (
      <div className='login'>
        <h2>Logowanie</h2>
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="username">Nazwa użytkownika:</label>
          <input
            type="text"
            id="username"
            name="username"
            value={username}
            onChange={this.handleInputChange}
            required
          />

          <label htmlFor="password">Hasło:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={this.handleInputChange}
            required
          />
          <div className='logi__redirect'>
            <p>Nie posiadasz konta?</p>
            <a href="/register">Załóż konto!</a>
          </div>

          <div className='logi__redirect guest'>
            <p>Spróbuj jako gość</p>
            <a href="/labirynt">Zagraj</a>
          </div>

          <button type="submit">Zaloguj się</button>
        </form>

        {error && <p>{error}</p>}
      </div>
    );
  }
}

// This is a new functional component that wraps around the class component
function LoginWrapper() {
  const navigate = useNavigate();

  const handleLoginSuccess = () => {
    navigate('/labirynt'); // Use the navigate function from useNavigate hook
  };

  return <Login onLoginSuccess={handleLoginSuccess} />;
}

export default LoginWrapper;
