import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import Login from './components/Login';
import Labirynt from './components/Labirynt';
import Register from './components/Register';
// import AuthCheck from './components/AuthCheck';
import './App.css';

window.addEventListener('keydown', function (e) {
  // Lista kodów klawiszy strzałek
  const arrowKeys = [37, 38, 39, 40];
  if (arrowKeys.indexOf(e.keyCode) > -1) {
    e.preventDefault(); // Zablokuj domyślne działanie klawisza
  }
}, false);

function App() {
  return (
    <BrowserRouter>
      {/* <AuthCheck /> */}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/labirynt" element={<Labirynt />} />

        {/* inne ścieżki i komponenty */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
