import React, { useState, useEffect } from 'react';
import Komorka from './Komorka';
import './Labirynt.css';

const Labirynt = () => {
  const szerokoscLabiryntu = 15;
  const wysokoscLabiryntu = 15;
  const liczbaPunktowDoZebrania = 10;
  const najlepszyCzas = localStorage.getItem('najlepszyCzas') || 'brak';


  const generujLabirynt = () => {
    const labirynt = Array.from({ length: wysokoscLabiryntu }, () => Array(szerokoscLabiryntu).fill('nieaktywna'));
    const sciany = [];


    const dodajSciany = (x, y) => {
      if (x > 0) sciany.push([x - 1, y]);
      if (y > 0) sciany.push([x, y - 1]);
      if (x < szerokoscLabiryntu - 1) sciany.push([x + 1, y]);
      if (y < wysokoscLabiryntu - 1) sciany.push([x, y + 1]);
    };

    const losowaPozycja = () => {
      const x = Math.floor(Math.random() * szerokoscLabiryntu);
      const y = Math.floor(Math.random() * wysokoscLabiryntu);
      return [x, y];
    };

    const [startX, startY] = losowaPozycja();
    labirynt[startY][startX] = 'ścieżka';
    dodajSciany(startX, startY);

    while (sciany.length > 0) {
      const index = Math.floor(Math.random() * sciany.length);
      const [x, y] = sciany[index];
      sciany.splice(index, 1);

      const sasiedzi = [];
      if (x > 0) sasiedzi.push(labirynt[y][x - 1]);
      if (y > 0) sasiedzi.push(labirynt[y - 1][x]);
      if (x < szerokoscLabiryntu - 1) sasiedzi.push(labirynt[y][x + 1]);
      if (y < wysokoscLabiryntu - 1) sasiedzi.push(labirynt[y + 1][x]);

      const sciezki = sasiedzi.filter(val => val === 'ścieżka');
      if (sciezki.length === 1) {
        labirynt[y][x] = 'ścieżka';
        dodajSciany(x, y);
      }
    }

    labirynt[0][1] = 'ścieżka';
    labirynt[wysokoscLabiryntu - 1][szerokoscLabiryntu - 2] = 'meta';

    for (let i = 0; i < 10; i++) {
      while (true) {
        const x = Math.floor(Math.random() * szerokoscLabiryntu);
        const y = Math.floor(Math.random() * wysokoscLabiryntu);
        if (labirynt[y][x] === 'ścieżka') {
          labirynt[y][x] = 'punkt';
          break;
        }
      }
    }


    for (let i = 0; i < 3; i++) {
      while (true) {
        const x = Math.floor(Math.random() * szerokoscLabiryntu);
        const y = Math.floor(Math.random() * wysokoscLabiryntu);
        if (labirynt[y][x] === 'ścieżka') {
          labirynt[y][x] = 'pulapka';
          break;
        }
      }
    }

    // labirynt[2][2] = 'pulapka';

    return labirynt;
  };


  const [labirynt, setLabirynt] = useState(generujLabirynt);
  const [pozycjaGracza, setPozycjaGracza] = useState({ x: 1, y: 1 });
  const [ukonczono, setUkonczono] = useState(false);
  const [punkty, setPunkty] = useState(0);
  // const [przegrana, setPrzegrana] = useState(false);
  const [komunikat, setKomunikat] = useState(false);
  const [punktyDoZebrania, setPunktyDoZebrania] = useState(liczbaPunktowDoZebrania);
  const [timer, setTimer] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [ostatnieCzasy, setOstatnieCzasy] = useState([]);
  const [ostatnieCzasyGlobal, setOstatnieCzasyGlobal] = useState([]);
  const [zdobytePunkty, setzdobytePunkty] = useState([]);
  // const [nazwaUzytkownika, setNazwaUzytkownika] = useState('');


  const zrestartujGre = () => {
    setLabirynt(generujLabirynt());
    setPozycjaGracza({ x: 1, y: 0 });
    setUkonczono(false);
    setKomunikat(false);
    setPunkty(0); // Dodane resetowanie punktów
    setTimer(0);
    setIsTimerRunning(false);
    setPunktyDoZebrania(liczbaPunktowDoZebrania);

  };


  const aktualizujNajlepszyCzas = (aktualnyCzas) => {
    const najlepszyCzas = localStorage.getItem('najlepszyCzas');
    if (!najlepszyCzas || aktualnyCzas < najlepszyCzas) {
      localStorage.setItem('najlepszyCzas', aktualnyCzas);
    }
  };

  const handleMove = (direction) => {
    if (ukonczono) return;

    const nowaPozycja = { ...pozycjaGracza };
    let ruchMozliwy = false;

    const canMove = (y, x) => labirynt[y][x] !== 'nieaktywna' && labirynt[y][x] !== 'ściana';

    switch (direction) {
      case 'up':
        if (pozycjaGracza.y > 0 && canMove(pozycjaGracza.y - 1, pozycjaGracza.x)) {
          nowaPozycja.y -= 1;
          ruchMozliwy = true;
        }
        break;
      case 'down':
        if (pozycjaGracza.y < wysokoscLabiryntu - 1 && canMove(pozycjaGracza.y + 1, pozycjaGracza.x)) {
          nowaPozycja.y += 1;
          ruchMozliwy = true;
        }
        break;
      case 'left':
        if (pozycjaGracza.x > 0 && canMove(pozycjaGracza.y, pozycjaGracza.x - 1)) {
          nowaPozycja.x -= 1;
          ruchMozliwy = true;
        }
        break;
      case 'right':
        if (pozycjaGracza.x < szerokoscLabiryntu - 1 && canMove(pozycjaGracza.y, pozycjaGracza.x + 1)) {
          nowaPozycja.x += 1;
          ruchMozliwy = true;
        }
        break;
      default:
        break;
    }

    // const dodajPulapki = () => {
    //   const szansaNaPulapke = 0.05; // 10% szansa na dodanie pułapki
    //   if (Math.random() < szansaNaPulapke) {
    //     while (true) {
    //       const x = Math.floor(Math.random() * szerokoscLabiryntu);
    //       const y = Math.floor(Math.random() * wysokoscLabiryntu);
    //       if (labirynt[y][x] === 'ścieżka' && (x !== pozycjaGracza.x || y !== pozycjaGracza.y)) {
    //         const nowyLabirynt = [...labirynt];
    //         nowyLabirynt[y][x] = 'pulapka';
    //         setLabirynt(nowyLabirynt);
    //         break;
    //       }
    //     }
    //   }
    // };


    if (ruchMozliwy) {
      // dodajPulapki();
      setPozycjaGracza(nowaPozycja);
      setIsTimerRunning(true);
    }
  };

  const zapiszWynikDoBazy = async (wynik) => {
    const apiUrl = 'https://pokonajlabirynt.pl/api/wyniki';

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(wynik),
      });


      if (response.ok) {
        console.log('Wynik zapisany pomyślnie');
      } else {
        console.error('Problem z zapisem wyniku do bazy danych:');
      }

    } catch (error) {
      console.error('Błąd przy zapisie do bazy danych:', error);
    }
  };

  const setGuestToken = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      localStorage.setItem('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTUsInVzZXJuYW1lIjoiZ3Vlc3QiLCJpYXQiOjE2OTk0ODAyODEsImV4cCI6MTY5OTU2NjY4MX0.1JesQN8H4A5nL09dW2XNWVjUDXty7tHPB-AqSM6tfO4');
    }
  }


  // Funkcja do pobierania wyników z bazy danych
  const pobierzWynikiZBazy = async () => {
    const apiUrl = 'https://pokonajlabirynt.pl/api/getwyniki';

    try {
      const response = await fetch(apiUrl, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': `application/json`
        }
      });
      if (!response.ok) {
        throw new Error('Nie udało się pobrać danych');
      }
      const wyniki = await response.json();
      setOstatnieCzasy(wyniki);
    } catch (error) {
      console.error('Błąd podczas pobierania wyników:', error);
    }
  };

  const pobierzWynikiZBazyGlobal = async () => {
    const apiUrl = 'https://pokonajlabirynt.pl/api/getwynikiglobal';

    try {
      const response = await fetch(apiUrl, {
        method: 'GET', // Explicitly define the method, even if GET is default
        headers: {
          'Content-Type': 'application/json', // This should be accepted, not content-type since you're expecting JSON
        },
      });

      // if (!response.ok) {
      //   const errorText = await response.text(); // Get the response body text
      //   throw new Error(`Nie udało się pobrać danych: ${response.status} ${response.statusText} - ${errorText}`);
      // }

      const wynikiGlobal = await response.json();
      setOstatnieCzasyGlobal(wynikiGlobal);
    } catch (error) {
      console.error('Błąd podczas pobierania wyników:', error);
    }
  };

  useEffect(() => {
    if (ukonczono) {
      aktualizujNajlepszyCzas(timer);

      // Pobieramy aktualne wyniki z bazy danych
      pobierzWynikiZBazy().then(ostatnieCzasy => {
        if (ostatnieCzasy) {
          const czyCzasIstnieje = ostatnieCzasy.some(element => element.czas === timer);
          if (!czyCzasIstnieje) {
            const bezpiecznyTimer = Number(timer);
            const bezpiecznePunkty = Number(punkty);

            if (!isNaN(bezpiecznyTimer) && !isNaN(bezpiecznePunkty) && bezpiecznyTimer > 0 && bezpiecznePunkty > 0) {
              const generalnePunkty = parseFloat(((bezpiecznePunkty / bezpiecznyTimer) * 100).toFixed(1));

              setzdobytePunkty(generalnePunkty);
              // Tutaj zakładamy, że funkcja zapiszWynikDoBazy() jest już zaimplementowana
              zapiszWynikDoBazy({
                czas: bezpiecznyTimer,
                punkty: bezpiecznePunkty,
                generalnePunkty
              });
            } else {
              console.error('Timer lub punkty mają nieprawidłową wartość.');
            }
          }
        }
      });
    }
  }, [ukonczono, timer, punkty]);



  useEffect(() => {
    if (ukonczono) {
      aktualizujNajlepszyCzas(timer);

      setOstatnieCzasy(prevCzasy => {
        const czyCzasIstnieje = prevCzasy.some(element => element.czas === timer);
        if (!czyCzasIstnieje) {
          const bezpiecznyTimer = Number(timer);
          const bezpiecznePunkty = Number(punkty);

          if (!isNaN(bezpiecznyTimer) && !isNaN(bezpiecznePunkty) && bezpiecznyTimer > 0 && bezpiecznePunkty > 0) {
            const generalnePunkty = parseFloat(((bezpiecznePunkty / bezpiecznyTimer) * 100).toFixed(1));

            const token = localStorage.getItem('token');

            // Zmienna na nazwę użytkownika
            let nazwaUzytkownika;

            // Dekodowanie tokenu JWT, aby pobrać nazwę użytkownika
            if (token) {
              try {
                // Dekodowanie payloadu tokenu
                const payload = JSON.parse(atob(token.split('.')[1]));

                // Przypisanie nazwy użytkownika do zmiennej
                nazwaUzytkownika = payload.username;
              } catch (error) {
                console.error('Błąd podczas dekodowania tokenu:', error);
              }
            }

            const nowyCzas = {
              czas: bezpiecznyTimer,
              punkty: bezpiecznePunkty,
              generalnePunkty,
              nazwaUzytkownika
            };

            // Wykonujemy zapis do bazy danych
            setzdobytePunkty(generalnePunkty);

            zapiszWynikDoBazy(nowyCzas);

            const noweCzasy = [...prevCzasy, nowyCzas].slice(-10);
            // localStorage.setItem('ostatnieCzasy', JSON.stringify(noweCzasy));
            return noweCzasy;
          } else {
            console.error('Timer lub punkty mają nieprawidłową wartość.');
            return prevCzasy;
          }
        } else {
          return prevCzasy;
        }
      });
    }
  }, [ukonczono, timer, punkty]);



  useEffect(() => {
    if (ukonczono) {
      setIsTimerRunning(false);
    }
  }, [ukonczono]);

  useEffect(() => {
    let intervalId;

    if (isTimerRunning) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isTimerRunning]);

  useEffect(() => {
    if (labirynt[pozycjaGracza.y][pozycjaGracza.x] === 'meta' && punktyDoZebrania === 0) {
      setUkonczono(true);
      pobierzWynikiZBazy();
      pobierzWynikiZBazyGlobal();
      // setPunkty(0); // Dodane resetowanie punktów

    }
  }, [pozycjaGracza, labirynt, punktyDoZebrania]);

  useEffect(() => {
    if (labirynt[pozycjaGracza.y][pozycjaGracza.x] === 'punkt') {
      setPunkty(punkty + 1);
      setPunktyDoZebrania(punktyDoZebrania - 1);
      const nowyLabirynt = labirynt.map(wiersz => [...wiersz]);
      nowyLabirynt[pozycjaGracza.y][pozycjaGracza.x] = 'ścieżka';
      setLabirynt(nowyLabirynt);
    }

    if (labirynt[pozycjaGracza.y][pozycjaGracza.x] === 'meta' && punktyDoZebrania === 0) {
      setUkonczono(true);
    }

    if (labirynt[pozycjaGracza.y][pozycjaGracza.x] === 'meta' && punktyDoZebrania > 0) {
      // setUkonczono(false);
      setKomunikat(true);

      setTimeout(() => {
        setKomunikat(false);
      }, 2000)

    }
  }, [pozycjaGracza, labirynt, punkty, punktyDoZebrania]);

  useEffect(() => {
    const obslugaKlawiszy = (e) => {
      switch (e.key) {
        case 'ArrowUp':
          handleMove('up');
          break;
        case 'ArrowDown':
          handleMove('down');
          break;
        case 'ArrowLeft':
          handleMove('left');
          break;
        case 'ArrowRight':
          handleMove('right');
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', obslugaKlawiszy);

    return () => {
      window.removeEventListener('keydown', obslugaKlawiszy);
    };



  }, [ukonczono, pozycjaGracza, wysokoscLabiryntu, szerokoscLabiryntu]);




  useEffect(() => {
    if (ukonczono) {
      aktualizujNajlepszyCzas(timer);
      // Można także zapisać liczbę punktów, jeśli jest to wymagane
      localStorage.setItem('liczbaPunktow', punkty);
    }
  }, [ukonczono, timer, punkty]);


  useEffect(() => {
    if (labirynt[pozycjaGracza.y][pozycjaGracza.x] === 'punkt') {
      setPunkty(punkty + 1);
      const nowyLabirynt = [...labirynt];
      nowyLabirynt[pozycjaGracza.y][pozycjaGracza.x] = 'ścieżka';
      setLabirynt(nowyLabirynt);
    }
  }, [pozycjaGracza]);

  useEffect(() => {
    if (labirynt[pozycjaGracza.y][pozycjaGracza.x] === 'pulapka') {
      setPunkty(punkty - 1);
      const nowyLabirynt = [...labirynt];
      nowyLabirynt[pozycjaGracza.y][pozycjaGracza.x] = 'ścieżka';
      setLabirynt(nowyLabirynt);
    }
  }, [pozycjaGracza]);

  useEffect(() => {
    const labirynt = generujLabirynt();
    setGuestToken();
    setIsTimerRunning(false); // Dodaj tę linijkę
    setLabirynt(labirynt);
    pobierzWynikiZBazy();
    pobierzWynikiZBazyGlobal();
  }, []);

  return (
    <div className="labirynt">
      <div className="timer">
        Czas: {timer} sekund
      </div>
      <div className="punkty">
        Zdobyte punkty: {punkty}/{liczbaPunktowDoZebrania}
      </div>
      {komunikat && (
        <div className="komunikat">
          Zbierz wszystkie prezenty!
        </div>
      )}
      {ukonczono && (
        <div className="komunikat">
          Gratulacje! Ukończyłeś labirynt!
          <p>Zdobyte punkty: {zdobytePunkty}</p>
          <button onClick={zrestartujGre}>Zagraj ponownie</button>
        </div>
      )}
      <div className='plansza'>
        {labirynt.map((wiersz, indeksY) => (
          <div key={indeksY} className="wiersz">
            {wiersz.map((komorka, indeksX) => (
              <Komorka
                key={indeksX}
                typ={komorka}
                jestGraczem={pozycjaGracza.x === indeksX && pozycjaGracza.y === indeksY}
              />
            ))}
          </div>
        ))}

      </div>

      <div className="cross-controls">
        <div className="row">
          <button onTouchStart={() => handleMove('up')}>Up</button>
        </div>
        <div className="row">
          <button onTouchStart={() => handleMove('left')}>Left</button>
          <button onTouchStart={() => handleMove('right')}>Right</button>
        </div>
        <div className="row">
          <button onTouchStart={() => handleMove('down')}>Down</button>
        </div>
      </div>

      <div className="czasy">
        <div className="czasy__login">
          <a href='/'>Zaloguj się</a>
        </div>
        <div className="najlepszy-czas">
          Najlepszy czas: {najlepszyCzas} sekund
        </div>
        <div className="ostatnie-czasy">
          <h2>Ostatnie Czasy:</h2>
          <ul>
            {ostatnieCzasy.map((element, index) => (
              <li key={index}>
                {element.nazwa_uzytkownika} / {element.generalne_punkty ? parseFloat(element.generalne_punkty.toFixed(1)) : 0} pkt
              </li>
            ))}
          </ul>
        </div>
        <div className="ostatnie-czasy">
          <h2>Najlepsi gracze:</h2>
          <ul>
            {ostatnieCzasyGlobal.map((element, index) => (
              <li key={index}>
                {element.nazwa_uzytkownika} / {element.generalne_punkty ? parseFloat(element.generalne_punkty.toFixed(1)) : 0} pkt

              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Labirynt;
