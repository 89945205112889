// Register.js

import React from 'react';
import axios from 'axios'; // make sure to install axios with npm or yarn

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      email: '',
      error: null,
      successMessage: null,

    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { username, password, email } = this.state;
    const apiUrl = 'https://pokonajlabirynt.pl/api/register';
    // URL to your backend endpoint

    try {
      const response = await axios.post(apiUrl, { username, password, email });
      // Handle response.data as required, potentially informing the user of success
      // and redirecting to the login page or directly logging them in
      console.log(response.data);
      this.setState({
        successMessage: 'Konto zostało pomyślnie utworzone!',
        error: null, // Wyczyść wcześniejsze błędy
      });

    } catch (error) {
      // Handle error cases, setting state to inform the user
      this.setState({
        error: error.response ? error.response.data : 'An error occurred!',
        successMessage: null, // Wyczyść wcześniejsze komunikaty o sukcesie
      });

    }

  };

  render() {
    const { username, password, email, error, successMessage } = this.state;

    return (
      <div className='login'>
        <h2>Rejestracja</h2>
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={this.handleInputChange}
            required
          />

          <label htmlFor="username">Nazwa użytkownika:</label>
          <input
            type="text"
            id="username"
            name="username"
            value={username}
            onChange={this.handleInputChange}
            required
          />

          <label htmlFor="password">Hasło:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={this.handleInputChange}
            required
          />

          <button type="submit">Zarejestruj się</button>
          <p >Masz już konto? <a href='/'>Zaloguj się!</a></p>
        </form>

        {error && <p>{error}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}

      </div>
    );
  }
}

export default Register;
