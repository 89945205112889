import React from 'react';
import './Komorka.css';

const Komorka = ({ typ, jestGraczem }) => {
  let klasa = 'komorka';

  if (jestGraczem) {
    klasa += ' gracz';
  } else if (typ === 'punkt') {
    klasa += ' prezent';
  } else {
    klasa += ` ${typ}`;
  }

  return <div className={klasa}></div>;
};


export default Komorka;
